<template>
  <div class="_main_wrapper relative flex font-poppins">
    <option-in-left class="i-left-panel shadow-one" />
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
      </div>

      <div class="survey-box bg-white shadow-three px-10 i-pt-30">
        <div class="flex gap-10 i-pb-25 border-secondary-five i-border-b-1">
          <button @click="activeTab = 1, responseMode = false">
            <div class="flex items-center gap-2 opacity-50" :class="{'opacity-100': activeTab === 1}">
              <div class="h-5 w-5 rounded-full border border-secondary-five fs-10 flex items-center justify-center">1</div>
              <span class="text-primary-one">Configure</span>
            </div>
          </button>
          <button @click="activeTab = 2, responseMode = true">
            <div class="flex items-center gap-2 opacity-50" :class="{'opacity-100': activeTab === 2}">
              <div class="h-5 w-5 rounded-full border border-secondary-five fs-10 flex items-center justify-center">2</div>
              Preview
            </div>
          </button>
          <button @click="activeTab = 3, responseMode = false">
            <div class="flex items-center gap-2 opacity-50" :class="{'opacity-100': activeTab === 3}">
              <div
                  class="h-5 w-5 rounded-full border border-secondary-five fs-10 flex items-center justify-center">3</div>
              <span class="text-primary-one">Share</span>
            </div>
          </button>
        </div>

        <div v-if="activeTab !== 3" class="survey-edit-box overflow-y-auto" :class="{'cursor-not-allowed': activeTab === 2}">
          <div v-if="activeTab === 1">
            <div class="flex items-center i-mt-25 gap-6">
              <input
                  @click="placeholder.title = null" v-click-outside="hideTitlePlaceholder"
                  type="text"
                  @keyup="regularUpdate(survey)"
                  :placeholder="placeholder.title"
                  style="width: 620px;"
                  class="title-field bg-transparent fs-16 h-10 font-poppins fw-600 text-primary-one placeholder-secondary-two border border-secondary-five rounded px-4"
                  v-model="survey.title">
            </div>
            <div class="flex items-center gap-3 i-mt-25">
            <textarea
                style="width: 620px"
                @click="placeholder.description = null" v-click-outside="hideDescriptionPlaceholder"
                @keyup="regularUpdate(survey)" :placeholder="placeholder.description"
                class="bg-transparent font-poppins fs-14 text-primary-one placeholder-secondary-two bld-content-area border border-secondary-five p-3 h-20 rounded"
                v-model="survey.description"></textarea>
            </div>
          </div>

          <div>
            <template v-for="(questionComponent, index) in survey.questions">
              <component class="question-item" :class="{'pointer-events-none': survey.responses_count > 0}" :is="getComponent(questionComponent.type)" :key="index" :serial="index" :item="questionComponent" :responseMode="responseMode"></component>
            </template>

            <div class="question-item">
              <textarea @click="placeholder.footer = null" v-click-outside="hideFooterPlaceholder" class="bg-transparent fs-14 font-roboto survey-footer grey-text footer-desc"
                        :placeholder="placeholder.footer" v-model="survey.footer" @keyup="regularUpdate(survey)"></textarea>
            </div>
          </div>
        </div>

        <send v-if="activeTab === 3" />
      </div>
    </div>
  </div>
</template>

<script>
import MultipleChoice from "../../../components/survey/question/MultipleChoice";
import Selection from "../../../components/survey/question/Selection";
import RatingScale from "../../../components/survey/question/RatingScale";
import Rating from "../../../components/survey/question/Rating";
import TextType from "../../../components/survey/question/TextType";
import OptionInLeft from "../../../components/survey/OptionInLeft";
import Dropdown from "../../../components/survey/question/Dropdown";
import OrderRanking from "../../../components/survey/question/OrderRanking";
import Slider from "../../../components/survey/question/Slider";
import DateTime from "../../../components/survey/question/DateTime";
import survey from '../../../common/survey';
import {mapGetters} from "vuex";
import Send from "@/views/screens/survey/Send";
export default {
  name: "Create",
  components: {
    Send,
    MultipleChoice, Selection, RatingScale, Rating, TextType, DateTime, Slider, OrderRanking, Dropdown, OptionInLeft},
  created() {
    document.title = "Survey";
    this.surveyCommonData = survey;
    this.$Progress.start();
    this.$store.dispatch("survey/getSingleSurvey", this.$route.params.survey_id).then(response => {
      this.$Progress.finish();
    });
  },
  data() {
    return {
      surveyCommonData: null,
      placeholder: {
        title: 'Survey title here',
        description: 'Add description here',
        footer: 'Add footer text here'
      },
      responseMode: false,
      activeTab: 1
    }
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey"
    })
  },
  methods: {
    gotoSurvey() {
      this.$router.push({ name: 'survey.landing' });
    },
    regularUpdate(survey) {
      this.$store.dispatch("survey/updateSurvey", survey);
    },
    getComponent(type) {
      let data = this.surveyCommonData.questionType;
      let item = data.find(item => parseInt(item.serial) === parseInt(type));
      if(item) {
        return item.component;
      }
      return '';
    },
    hideTitlePlaceholder() {
      this.placeholder.title = 'Survey title here';
    },
    hideDescriptionPlaceholder() {
      this.placeholder.description = 'Add Survey description here';
    },
    hideFooterPlaceholder() {
      this.placeholder.footer = 'Add footer text here';
    },
    removeOption(item, index) {
      item.options.splice(index, 1)
      this.updateQuestion(item)
    },
    updateQuestion(item) {
      this.$Progress.start();
      this.$store.dispatch("survey/updateQuestion", item).then(response => {
        this.$Progress.finish();
      });
    },
    duplicateQuestion(item) {
      delete item.id
      this.$Progress.start();
      this.$store.dispatch("survey/storeQuestion", item).then(response => {
        this.$Progress.finish();
      });
    },
    deleteQuestion(item) {
      this.$Progress.start();
      this.$store.dispatch("survey/deleteQuestion", item).then(response => {
        this.$Progress.finish();
      });
    }
  }
}
</script>

<style scoped>
.survey-box {
  width: 1455px;
  height: 920px;
  margin-left: 40px;
  margin-top: 20px;
  border-radius: 4px;
}
.breadcrumb span {
  margin-right: 10px;
}
.message-box {
  background: #232326;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  height: 895px;
}
.message-heading {
  padding: 16px 0;
  border-bottom: 1px solid #414141;
}

.assessment-blue-text {
  color: #4F7982;
}

.assessment-blue-text:hover {
  color: #29799b;
}
.survey-title::placeholder {
  color: #C4C4C4;
}
.textarea-desc {
  width: 1220px;
  height: 100px;
}
.textarea-desc::placeholder, .footer-desc::placeholder {
  color: #787878;
  padding-top: 40px;
}
.add-cover-btn {
  border: 1px solid #787878;
  border-radius: 4px;
}
.create-desc-box {
  border-bottom: 1px solid #414141;
}
.question-section {
  overflow-y: auto;
  height: 705px;
}
.question-item {
  padding: 30px 0;
  border-bottom: 1px solid #C9C9C9;
}
.survey-footer {
  height: 80px;
}
.survey-footer::placeholder {
  color: #787878;
}
.survey-edit-box {
  height: 810px;
}
</style>
